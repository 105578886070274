<template>
  <CRow>
    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center">
              <strong>{{ $lang.profile.non_verified_profile_title }}</strong>
            </CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="exports(self, 'pdf', module, 'Profiles')"
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
                <BeforeFilter
                    :filterShow="toggleFilterValue"
                    @getFilterToggle="switchFilter"
                ></BeforeFilter>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <!--        {{ showAlert }}-->
        <!--        <alerts :message="alertMessage" type="success" :v-if="showAlert"/>-->
        <CCardBody>
          <transition name="fade">
            <CRow v-if="toggleFilterValue">
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.industry
                    }}</label>
                  <v-select
                      :options="industryOptions"
                      v-model="profile.industry"
                      @option:selected="getSubIndustry"
                  >
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.subIndustry
                    }}</label>
                  <v-select
                      multiple
                      :options="subIndustryOptions"
                      v-model="profile.subIndustry"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.country
                    }}</label>
                  <v-select
                      :options="countryOptions"
                      v-model="profile.country"
                      @option:selected="loadState"
                  >
                  </v-select>
                </div>
              </CCol>
              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.state
                    }}</label>
                  <v-select
                      :options="StateOptions"
                      v-model="profile.state"
                      @option:selected="loadCity"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.city
                    }}</label>
                  <v-select :options="cityOptions" v-model="profile.city">
                  </v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.profileVerified
                    }}</label>
                  <v-select
                      :options="profileVerifiedOptions"
                      v-model="profile.profileVerified"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group">
                  <label class="form__label">{{
                      $lang.profile.filter.user
                    }}</label>
                  <v-select
                      :options="userOptions"
                      v-model="profile.user"
                  ></v-select>
                </div>
              </CCol>

              <CCol sm="3">
                <div class="form-group" style="margin-top: 30px">
                  <CButton
                      type="submit"
                      size="sm"
                      color="primary"
                      @click="applyFilter()"
                      v-c-tooltip="$lang.common.filter.button.filter"
                  >
                    {{ $lang.common.filter.button.filter }}
                  </CButton>
                  &nbsp;
                  <CButton
                      color="danger"
                      size="sm"
                      @click="resetFilter()"
                      v-c-tooltip="$lang.common.filter.button.cancel"
                  >
                    {{ $lang.common.filter.button.cancel }}
                  </CButton>
                </div>
              </CCol>
            </CRow>
          </transition>

          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >

          <v-server-table
              :columns="columns"
              :url="listUrl"
              :options="options"
              ref="myTable"
              @loaded="exports(self, 'Excel', module, 'NonVerified Profiles')"
          >
            <template #isVerified="data">
              <CBadge :color="statusBudget(data.row.isVerified)">{{ data.row.isVerified }}</CBadge>
            </template>
            <template #title="data">
              <span :title="data.row.title">{{
                  trimfunction(data.row.title, 20)
                }}</span>
            </template>
            <template #name="data">
              <Avatar v-if="data.row.type==='Individual'"
                      :image="data.row.avatar"
                      :defaultImage="data.row.avatar"
                      :content="data.row.name"
                      :length="20"
              />
              <span v-else> <center> --- </center>  </span>
            </template>
            <template #company="data">
              <Avatar v-if="data.row.type==='Company'"
                      :image="data.row.avatar"
                      :defaultImage="data.row.avatar"
                      :content="data.row.company"
                      :length="20"
              />
              <span v-else> <center> --- </center>  </span>
            </template>

            <template #actions="data">
              <CButton
                  color="primary"
                  v-c-tooltip="$lang.buttons.general.crud.view"
                  v-on:click="viewTableRow(data.row.userId)"
                  v-if="checkPermission('profile-verification')"
              >
                <i class="fas fa-user"></i>
              </CButton>
            </template>
          </v-server-table>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import Vue from "vue";
import VueForm from "vue-form";
import store from "/src/store/store.js";
import {
  pendingprofiles,
  city,
  country,
  industry,
  profileSubIndustry,
  state,
  users,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import BeforeFilter from "../BeforeFilterOpen";
import VueJsonToCsv from "vue-json-to-csv";
import Avatar from "/src/components/Avatar";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);

export default {
  name: "PendingProfilesList",
  mixins: [Mixin],
  components: {
    BeforeFilter,
    VueJsonToCsv,
    Avatar,
  },
  data() {
    return {
      type: '',
      json_data: [],
      json_label: {},
      json_title: "",
      toggleFilterValue: false /* for filter toggle*/,
      err_msg: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      industryOptions: [],
      subIndustryOptions: [],
      selectedIndustry: [],
      countryOptions: [],
      StateOptions: [],
      cityOptions: [],
      userOptions: [],
      profileVerifiedOptions: [],
      activePage: 1,
      listUrl: "/users/profiles/pending/list",
      module: pendingprofiles,
      moduleIndustry: industry,
      moduleSubIndustry: profileSubIndustry,
      moduleUser: users,
      moduleCountry: country,
      moduleState: state,
      moduleCity: city,
      profile: {
        name: "",
        isVerified: "",
        country: "",
        state: "",
        city: "",
      },
      columns: ['type', "company", "name", "title", "isVerified", "actions"],
      data: [],
      options: {
        headings: {
          type: this.$lang.profile.table.type,
          company: this.$lang.profile.table.company,
          name: this.$lang.profile.table.name,
          isVerified: this.$lang.profile.table.status,
          title: this.$lang.profile.table.title,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["name", "isVerified"],
        sortable: ["name", "isVerified"],
        filterable: ["name", "isVerified"],
      },
    };
  },
  mounted() {
    let self = this;
    // self.data = this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;

    self.profileVerifiedOptions.push(
        {value: "1", label: "Pending"},
        {value: "2", label: "Verified"},
        {value: "3", label: "Blocked"}
    );

    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.name});
      });
    });

    axios.get(this.listUrlApi(this.moduleIndustry)).then((response) => {
      response.data.data.map(function (value, key) {
        // self.industryLists.push({value: value.id, label: value.title});
        if (value.industryId === "") {
          self.industryOptions.push({value: value.id, label: value.industry});
        }
      });
    });
    axios.get(this.listUrlApi(this.moduleCountry)).then((response) => {
      response.data.data.map(function (value, key) {
        self.countryOptions.push({value: value.id, label: value.name});
      });
    });

    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      this.setAlert(true);
    }
    this.setAlert(false);
    localStorage.setItem("showAlert", "");
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    /* get from child component */
    switchFilter(show) {
      this.toggleFilterValue = show;
    },

    //Filer
    loadState() {
      let self = this;
      const postData = {countryId: self.profile.country.value};
      self.profile.state = {};
      self.profile.city = {};
      axios
          .post(this.listUrlApi(this.moduleState), postData)
          .then((response) => {
            self.StateOptions = [];
            response.data.data.map(function (value, key) {
              self.StateOptions.push({value: value.id, label: value.name});
            });
          });
    },
    loadCity() {
      let self = this;
      const postData = {stateId: self.profile.state.value};
      self.profile.city = {};
      axios
          .post(this.listUrlApi(this.moduleCity), postData)
          .then((response) => {
            self.cityOptions = [];
            response.data.data.map(function (value, key) {
              self.cityOptions.push({value: value.id, label: value.name});
            });
          });
    },
    getSubIndustry() {
      let self = this;
      if (self.profile.industry.value > 0) {
        const postData = {industry_id: self.profile.industry.value};
        axios
            .post(this.listUrlApi(this.moduleIndustry), postData)
            .then((response) => {
              self.subIndustryOptions = [];
              response.data.data.map(function (value, key) {
                if (value.industryId > 0) {
                  self.subIndustryOptions.push({
                    value: value.id,
                    label: value.SubIndustry,
                  });
                }
              });
            });
      }
    },
    //END

    setAlert(value) {
      this.showAlert = value;
    },
    deletePopup(item) {
      this.$swal({
        title: this.$lang.common.delete_swal.are_you,
        type: "warning",
        showCancelButton: true,
        confirmButtonColor: "#e55353",
        confirmButtonText: this.$lang.common.delete_swal.yes_delete,
        cancelButtonText: this.$lang.common.delete_swal.cancel,
      }).then((result) => {
        // <--
        if (result.value) {
          // <-- if accepted
          let self = this;
          axios
              .delete(this.deleteUrlWeb(this.module, item), self)
              .then(function (response) {
                if (response.status === 200) {
                  self.$refs.myTable.refresh();
                  self.alertMessage = response.data.response.message;
                  self.dismissCountDownS = 10;
                  // self.$router.go()
                }
              });
        }
      });
    },
    createTableRow() {
      this.$router.push({name: "Profile Create"});
    },
    editTableRow(item) {
      this.$router.push({path: `profile/edit/${item}`});
    },
    viewTableRow(item) {
      this.$router.push({path: `pending-profiles/verification/${item}`});
    },
    viewAllTableRow(item) {
      this.$router.push({path: `profile/all/${item}`});
    },
    verificationTableRow(item) {
      this.$router.push({path: `profile/verification/${item}`});
    },
    profileUpdateRequestListTableRow(item) {
      this.$router.push({path: `profile/update-requests/${item}`});
    },

    viewSocialLinkTableRow(item) {
      this.$router.push({path: `profile/social-links/${item}`});
    },
    rowClicked(item) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              let responseData = response.data.data;
              if (responseData.type === 'Individual') {
                let value = 'company';
                self.columns = self.columns.filter(item => item !== value)
              } else {
//
              }
            } else {
              self.data = [];
            }
          });
    },

    applyFilter() {
      let industry = "";
      let user = "";
      let country = "";
      let state = "";
      let city = "";
      let profileVerified = "";

      let getSubIndustries = [];

      if (this.profile.industry != null) {
        industry = this.profile.industry.value;
      }
      if (this.profile.subIndustry != null) {
        this.profile.subIndustry.map(function (value, key) {
          getSubIndustries[key] = value.value;
        });
      }
      if (this.profile.user != null) {
        user = this.profile.user.value;
      }
      if (this.profile.country != null) {
        country = this.profile.country.value;
      }
      if (this.profile.state != null) {
        state = this.profile.state.value;
      }
      if (this.profile.city != null) {
        city = this.profile.city.value;
      }
      if (this.profile.profileVerified != null) {
        profileVerified = this.profile.profileVerified.value;
      }
      const reqObj = {
        industry: industry,
        subIndustry: getSubIndustries,
        user: user,
        country: country,
        state: state,
        city: city,
        profileVerified: profileVerified,
      };
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = reqObj;
      this.$refs.myTable.refresh();
    },

    resetFilter() {
      this.profile.industry = "";
      this.profile.subIndustry = "";
      this.profile.user = {};
      this.profile.country = {};
      this.profile.state = {};
      this.profile.city = {};
      this.profile.profileVerified = {};
      store.commit("showLoader", true); // Loader Start
      this.$refs.myTable.customQueries = {};
      this.$refs.myTable.refresh();
    },
  },
};
</script>
